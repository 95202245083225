import { Chip, Zoom } from "@mui/material";

export default function Responsibilities({ workExperiences }) {

  const allResponsibilities = workExperiences.reduce((acc, experience) => {
    if (experience.responsibilities_object) {
      experience.responsibilities_object.forEach(responsibility => {
        if(!acc.find(item => item.name === responsibility.name)){
          acc.push(responsibility)
        }
      })
    }
    return acc;
  }, []);

  return (
      <>
        {allResponsibilities.map((responsibility, index) => {
          const formattedResponsibility =
              responsibility.name.charAt(0).toUpperCase() + responsibility.name.slice(1);
          return (
              <Zoom in key={index}>
                <Chip
                    label={formattedResponsibility}
                    variant="outlined"
                    size={"small"}
                    sx={{
                      fontVariationSettings: `'wdth' 88, 'wght' 500`,
                    }}
                />
              </Zoom>
          );
        })}
      </>
  );
}
