import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Stack, } from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CallIcon from '@mui/icons-material/Call';
import HailIcon from '@mui/icons-material/Hail';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import FiltersDrawer from "../FiltersDrawer";
import { getContrastColor } from "../../lib/helpers";

function Layout() {
  const location = useLocation();
  const cartCounter = useSelector(
      (store) => store.cartSlice.selectedStaff.length
  );
  const current = useSelector((store) => store.filterSlice.filteredEmployees);
  const { isFiltered, totalEmployees } = useSelector((store) => store.filterSlice);
  const [filterOpen, setFilterOpen] = useState(false);

  const { style } = useSelector(state => state.settingsSlice.settings)

  return (
      <div style={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
        overflowX: 'hidden'
      }}>
        <main style={{
          width: 'inherit', display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <FiltersDrawer
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
              current={current}
              total={totalEmployees}
          />
          <Outlet/>
        </main>
        {location.pathname !== '/cart' && location.pathname !== '/404' &&
            <footer style={{
              zIndex: '2000',
              minWidth: '100%',
              position: 'fixed',
              boxShadow: '0 0 .8rem rgba(0, 0, 0, .15)',
              padding: '1rem',
              backdropFilter: 'blur(1rem) saturate(180%)',
              bottom: '0',
              backgroundColor: 'rgba(251, 251, 253, .90)',
              borderRadius: '1rem 1rem 0 0',
              borderTop: '1px solid #f1f1f1',
            }}>
              <Stack role={'nav_panel'} sx={{
                flexFlow: 'row nowrap',
                gap: '3%',
                justifyContent: 'center',
              }}>

                <Button
                    variant='contained'
                    component={Link}
                    to='/'
                    startIcon={<HailIcon/>}
                    sx={{
                      fontSize: 'clamp(.8rem, 1.2vw, 1rem)',
                      borderRadius: '.5rem',
                    }}
                    onClick={() => filterOpen && setFilterOpen(!filterOpen)}
                >
                  Staff
                </Button>
                <Button
                    variant='contained'
                    startIcon={<FilterAltOutlinedIcon/>}
                    disabled={location.pathname !== '/'}
                    sx={{
                      color: getContrastColor(style.footer.filterBtnBGColor),
                      backgroundColor: style.footer.filterBtnBGColor || 'grey',
                      borderRadius: '.5rem',
                      fontSize: 'clamp(.8rem, 1.2vw, 1rem)',
                      '&:hover': {
                        backgroundColor: style.footer.filterBtnBGColor,
                        filter: 'brightness(1.2)',
                      },
                      '&.filter-selected': {
                        backgroundColor: style.footer.filterBtnBGColor,
                        filter: 'invert(1)',
                        '&:disabled': {
                          // backgroundColor: filterBtnBGColor,
                          filter: 'brightness(0.8) invert(1) opacity(80%)',
                        },
                      },
                    }}
                    onClick={() => setFilterOpen(!filterOpen)}
                    className={isFiltered ? 'filter-selected' : ''}
                >
                  Filter
                </Button>
                <Button
                    variant='contained'
                    component={Link}
                    to='/cart'
                    onClick={() => filterOpen && setFilterOpen(!filterOpen)}
                    startIcon={cartCounter > 0 ? <ShoppingBagIcon/> : <CallIcon/>}
                    sx={{
                      fontSize: 'clamp(.8rem, 1.2vw, 1rem)',
                      borderRadius: '.5rem',
                    }}>
                  {cartCounter > 0 ? 'Book an interview' : 'Book a call'}
                </Button>
              </Stack>
            </footer>
        }
      </div>
  );
}

export { Layout };
