import {
  Chip,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { grey } from "@mui/material/colors";

export default function Experience({ item }) {
  const [open, setOpen] = useState(false);
  const { company_name, position, responsibilities_text } = item;
  const startDate = new Date(item.start_date).toLocaleDateString("default", {
    year: "numeric",
    month: "2-digit",
  });
  const endDate = item.end_date
      ? new Date(item.end_date).toLocaleDateString("default", {
        year: "numeric",
        month: "2-digit",
      })
      : "Present";
  return (
      <>
        <ListItemButton onClick={() => setOpen(!open)} selected={open}>
          <ListItemIcon>
            <BusinessIcon/>
          </ListItemIcon>
          <ListItemText
              primaryTypographyProps={{
                component: "div",
              }}
              primary={
                <Typography
                    sx={{
                      fontVariationSettings: `'wdth' 80, 'wght' 600`,
                    }}>
                  {company_name}
                </Typography>
              }
              secondaryTypographyProps={{
                component: "div",
              }}
              secondary={
                <Stack
                    sx={{
                      flexFlow: "row nowrap",
                      gap: "1rem",
                    }}>
                  <Stack
                      sx={{
                        flexFlow: "row wrap",
                        alignItems: "center",
                        gap: ".3rem 1rem",
                      }}>
                    {position}
                    <Chip
                        icon={<AccessTimeIcon/>}
                        label={`${startDate} - ${endDate}`}
                        size={"small"}
                        variant="filled"
                        sx={{
                          backgroundColor: grey[50],
                        }}
                    />
                  </Stack>
                </Stack>
              }
          />
          {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemText
                primaryTypographyProps={{
                  component: "div",
                }}
                secondaryTypographyProps={{
                  component: "div",
                }}
                primary={
                  <Typography variant={"body2"}>{responsibilities_text}</Typography>
                }
                secondary={
                  <Stack
                      sx={{
                        flexFlow: "row wrap",
                        gap: "1rem",
                        padding: ".5rem 0",
                      }}>
                    {item.experience_tags.map((tag) => (
                        <Chip label={tag} key={tag} size={"small"}/>
                    ))}
                  </Stack>
                }
                sx={{
                  padding: "1rem",
                }}
            />
          </List>
        </Collapse>
      </>
  );
}
