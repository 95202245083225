import React from "react";
import { Chip, Fade } from "@mui/material";
import { getContrastColor } from "../../../../lib/helpers";
import { useSelector } from "react-redux";

export function EmpSkills({ empSkills }) {

  const { style } = useSelector(state => state.settingsSlice.settings)

  let showedSkills = 6;

  const hiddenCount = empSkills.length - showedSkills;

  const skillsRow = empSkills.length > showedSkills
      ? empSkills.slice(0, showedSkills)
      : empSkills

  return (
      <>
        {skillsRow
            ? skillsRow.map((item) => {
              return (
                  <Fade in key={item.name}>
                    <Chip
                        component={"span"}
                        size="small"
                        variant={"outlined"}
                        label={item.name}
                        sx={{
                          fontVariationSettings: `'wdth' 85, 'wght' 500`,
                          color: style.card.chipTextColor,
                          borderColor: style.card.chipBorderColor,
                        }}
                    />
                  </Fade>
              );
            })
            : ""}

        {hiddenCount > 0 && (
            <Chip
                size="small"
                variant="filled"
                label={`+${hiddenCount}`}
                sx={{
                  fontVariationSettings: `'wdth' 90, 'wght' 600`,
                  userSelect: "none",
                  color: getContrastColor(style.main.primaryColor),
                  backgroundColor: style.main.primaryColor,
                }}
            />
        )}
      </>
  );
}
