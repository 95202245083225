import  mainLogo from "../../assets/logo_svg_full.svg";
import { useSelector } from "react-redux";

function Logo({ size }) {

  const {style} = useSelector(state => state.settingsSlice.settings)

  return <img
      style={{
        minWidth: "280px",
        width: size === 's' ? '30%' : '90%',
      }}
      src={style.main.logo || mainLogo}
      alt={'logo-full'}
  />
}

export { Logo }




