import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Skeleton,
  Stack,
} from "@mui/material";

const SkeletonMain = ({ small }) => {
  return (
      <Stack
          sx={{
            flexFlow: "row wrap",
            justifyContent: "space-evenly",
            gap: "1.5dvw",
            rowGap: "2.4dvw",
            transition: "all .2s ease",
            alignItems: "center",
            flexGrow: "1",
            padding: '1rem',
            paddingBottom: '5rem'
          }}
      >
        {small ?
            Array.from(new Array(16)).map((item, index) => (
                <Card key={index}
                      component={"article"}
                      variant="outlined"
                      sx={{
                        minWidth: '20rem',
                        // width: '20rem',
                        maxWidth: '30%',
                        flexGrow: '1',
                        lineHeight: '1'
                      }}
                >
                  <CardContent
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "stretch",
                        gap: "1rem",
                        justifyContent: "flex-start",
                      }}
                  >
                    <Stack
                        component={"section"}
                        sx={{
                          display: "flex",
                          flexFlow: "row nowrap",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                    >
                      <Skeleton
                          variant="circular"
                          sx={{
                            width: '4.5rem',
                            height: '4.5rem',
                            display: "flex",

                          }}
                      />
                    </Stack>
                    <Stack
                        component={"section"}
                        flexGrow={1}
                        sx={{
                          justifyContent: "center",
                          gap: ".5rem",
                        }}
                    >
                      <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                        <Skeleton component={Chip} variant="chip"
                                  width={100} height={18}
                        />
                        <Skeleton component={Chip} variant="chip"
                                  width={56} height={20}/>
                      </Box>
                      <Skeleton variant="text"
                                width={120} height={16}
                      />
                    </Stack>
                  </CardContent>
                  <Stack width={"100%"}>
                    <Skeleton
                        sx={{
                          alignSelf: 'center',
                          width: '110px',
                          height: '40px',
                          marginBottom: '6px'
                        }}
                    />
                  </Stack>
                </Card>
            ))
            :
            Array.from(new Array(10)).map((item, index) => (
                <Card key={index}
                      component={"article"}
                      sx={{
                        width: "20rem",
                        aspectRatio: "0.66",
                        display: "flex",
                        flexFlow: "column nowrap",
                        alignItems: "center",
                      }}
                >
                  <Box
                      component={"header"}
                      sx={{
                        width: "100%",
                        minHeight: "3rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: ".5rem",
                      }}
                  >
                    <Skeleton variant="text"
                              width={68} height={24}/>
                    <Skeleton component={Chip} variant="chip"
                              width={136} height={24}
                    />
                  </Box>
                  <CardContent sx={{
                    width: "100%",
                    padding: "0 0 .5rem 0",
                  }}>
                    <Stack
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexFlow: "column nowrap",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: ".5rem 0 1rem 0",
                        }}
                    >
                      <Skeleton
                          variant="circular"
                          sx={{
                            height: '192px',
                            width: '192px',
                            display: "flex",

                          }}
                      />
                    </Stack>
                    <Stack component={"section"} width={"100%"}>
                      <Skeleton
                          sx={{
                            alignSelf: 'center',
                            width: '120px',
                            height: '42px',
                          }}
                      />
                      <Skeleton
                          sx={{
                            alignSelf: 'center',
                            width: '180px',
                            height: '18px',
                          }}
                      />
                    </Stack>
                    <Box
                        component={"section"}
                        sx={{
                          width: "100%",
                          flexGrow: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexFlow: "row wrap",
                          alignContent: "center",
                          padding: "2rem 1rem",
                          paddingBottom: '1rem',
                          gap: ".5rem .3rem",
                          textWrap: "balance",
                        }}
                    >
                      <Skeleton component={Chip} variant="chip"
                                width={76} height={24}
                      />
                      <Skeleton component={Chip} variant="chip"
                                width={52} height={24}
                      />
                      <Skeleton component={Chip} variant="chip"
                                width={45} height={24}
                      />
                      <Skeleton component={Chip} variant="chip"
                                width={70} height={24}
                      />
                      <Skeleton component={Chip} variant="chip"
                                width={50} height={24}
                      />
                      <Skeleton component={Chip} variant="chip"
                                width={62} height={24}
                      />
                    </Box>
                    <Stack width={"100%"}>
                      <Skeleton
                          sx={{
                            alignSelf: 'center',
                            width: '180px',
                            height: '50px',
                          }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
            ))
        }
      </Stack>
  );
};

export default SkeletonMain;
