import { Stack } from "@mui/material";
import React from "react";
import { BookingForm } from "../components/BookingForm/BookingForm";
import { BookingData } from "../components/BookingData";
import { AnimateGradientBg } from "../components/AnimateGradientBg";

function CartPage() {
  return (
      <Stack flexDirection={'row'} flexWrap={'wrap'}
             sx={{ minHeight: '100vh', alignSelf: 'normal' }}>
        <Stack
            justifyContent={'center'}
            alignItems={'center'}
            width={'50%'}
            minWidth={'27rem'}
            padding={'1rem'}
            gap={'1rem'}
            flexGrow={1}
            sx={{
              background: 'rgb(249,251,253)'
            }}
        >
          <BookingData/>
        </Stack>
        <AnimateGradientBg>
          <Stack
              justifyContent={'center'}
              alignItems={'center'}
              minWidth={'25rem'}
              width={'50%'}
              padding={'1rem'}
              flexGrow={1}
          >
            <BookingForm/>
          </Stack>
        </AnimateGradientBg>
      </Stack>
  );
}

export { CartPage };
