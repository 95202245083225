import { configureStore } from "@reduxjs/toolkit";
import filterSlice from "./slices/filterSlice";
import cartSlice from "./slices/cartSlice";
import settingsSlice from "./slices/settingsSlice";

export const store = configureStore({
  reducer: {
    filterSlice,
    cartSlice,
    settingsSlice
  },
});
