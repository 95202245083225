import { createTheme } from '@mui/material/styles';
import { getContrastColor } from "../helpers";
import { useSelector } from "react-redux";

const DynamicTheme = () => {
  const { style } = useSelector(state => state.settingsSlice.settings)
  const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

  return createTheme({
    palette: {
      background: {
        default: style.main.backgroundColor || "rgb(249,251,253)",
      },
      primary: {
        main: style.main.primaryColor,
      },
      secondary: {
        main: style.main.secondaryColor,
      },
    },
    typography: {
      fontFamily: 'OpenSans-VariableFont, sans-serif',
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: style.main.borderRadius,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: style.main.borderRadius,
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            '& .MuiToggleButton-root.Mui-selected': {
              color: getContrastColor(style.main.backgroundColor),
              borderColor: getContrastColor(style.main.backgroundColor),
              opacity: '1'
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: style.primaryColor,
            height: 2,
            padding: '15px 0',
            '& .MuiSlider-thumb': {
              height: 28,
              width: 28,
              backgroundColor: style.primaryColor,
              boxShadow: iOSBoxShadow,
              '&:focus, &:hover, &.Mui-active': {
                boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                '@media (hover: none)': {
                  boxShadow: iOSBoxShadow,
                },
              },
            },
            '& .MuiSlider-valueLabel': {
              backgroundColor: '#fff',
              color: 'rgba(0, 0, 0, 0.6)',
              boxShadow: iOSBoxShadow,
            },
            '& .MuiSlider-track': {
              border: 'none',
            },
            '& .MuiSlider-rail': {
              opacity: 0.5,
              backgroundColor: '#bfbfbf',
            },
            '& .MuiSlider-mark': {
              backgroundColor: '#bfbfbf',
              height: 8,
              width: 1,
              '&.MuiSlider-markActive': {
                opacity: 1,
                backgroundColor: 'currentColor',
              },
            },
          },
        }
      }
    },
  });
}
export default DynamicTheme;
