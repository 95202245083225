import {
  Chip,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import SchoolIcon from "@mui/icons-material/School";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { grey } from "@mui/material/colors";

export default function Education({ item }) {
  const {
    comment,
    degree_name,
    education_tags,
    end_date,
    institution_name,
    specialisation,
    start_date,
  } = item;

  const [open, setOpen] = useState(false);
  const startDate = new Date(start_date).toLocaleDateString("default", {
    year: "numeric",
    month: "2-digit",
  });
  const endDate = end_date
      ? new Date(end_date).toLocaleDateString("default", {
        year: "numeric",
        month: "2-digit",
      })
      : "Present";
  return (
      <>
        <ListItemButton onClick={() => setOpen(!open)} selected={open}>
          <ListItemIcon>
            <SchoolIcon/>
          </ListItemIcon>
          <ListItemText
              primaryTypographyProps={{ component: "div" }}
              secondaryTypographyProps={{ component: "div" }}
              primary={
                <Typography
                    sx={{
                      // fontFamily: "unset",
                      // fontWeight: "600",
                      fontVariationSettings: `'wdth' 80, 'wght' 600`,
                    }}>
                  {institution_name}
                </Typography>
              }
              secondary={
                <Stack sx={{
                  flexFlow: "row nowrap",
                  gap: "1rem",
                }}>
                  <Stack sx={{
                    flexFlow: "row wrap",
                    alignItems: "center",
                    gap: ".3rem 1rem",
                  }}>
                    {`${specialisation} - ${degree_name}`}
                    <Chip
                        icon={<AccessTimeIcon/>}
                        label={`${startDate} - ${endDate}`}
                        size={"small"}
                        variant="filled"
                        sx={{
                          backgroundColor: grey[50],
                        }}
                    />
                  </Stack>
                </Stack>
              }
          />
          {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemText
                primaryTypographyProps={{ component: "div" }}
                secondaryTypographyProps={{ component: "div" }}
                primary={<Typography variant={"body2"}>{comment}</Typography>}
                secondary={
                  <Stack sx={{
                    flexFlow: "row wrap",
                    gap: "1rem",
                    padding: ".5rem 0",
                  }}>
                    {education_tags.map((tag) => (
                        <Chip label={tag} key={tag} size={"small"}/>
                    ))}
                  </Stack>
                }
                sx={{ padding: "1rem", }}
            />
          </List>
        </Collapse>
      </>
  );
}
