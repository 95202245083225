import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import mainLogo from "../../assets/logo_svg_full.svg";
import { SETTINGS_URL } from "../../constants";

export const initialState = {
  isLoading: true,
  error: null,
  settings: {
    style: {
      main: {
        backgroundColor: '#F9FBFD',
        primaryColor: '#1677FF',
        secondaryColor: '#ff7807',
        borderRadius: '10px',
        logo: mainLogo
      },
      card: {
        avatarBackground: '#1677FF',
        cvAvatarBackground: '#1677FF',
        chipBorderColor: '#1677FF',
        chipTextColor: '#1677FF',
      },
      footer: {
        filterBtnBGColor: '#DF6703',
      },
    },
    filter: {
      department: [],
      position: [],
      language: []
    },
    cardsConfig: {
      priceCoeff: 1
    },
    affiliateCode: '',
  }
};

export const getSettings = createAsyncThunk(
    "settings/fetchSettings",
    async (customersAffiliateCode) => {
      try {
        const { data } = await axios({
          url: `${SETTINGS_URL}${customersAffiliateCode}`,
        });
        const rawData = data.data.moduleSettings;
        const cleanedData = rawData.replace(/\\/g, '');
        return {
          settings: JSON.parse(cleanedData),
          affiliateCode: customersAffiliateCode,
        };
      } catch (error) {
        console.error('Error fetching settings:', error);
        throw error;
      }
    }
);


export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getSettings.pending, (state) => {
          state.error = null;
          state.isLoading = true;
        })
        .addCase(getSettings.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = null;
          state.settings = action.payload.settings;
          state.affiliateCode = action.payload.affiliateCode;
        })
        .addCase(getSettings.rejected, (state, action) => {
          state.error = action.error;
          state.isLoading = false;
        })
  },
});

export default settingsSlice.reducer;
