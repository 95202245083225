import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import React from "react";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import "./index.css";
import ThemeRegistry from "./lib/ThemeRegistry/ThemeRegistry";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
      <ThemeRegistry>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </ThemeRegistry>
    </Provider>
)
;
