import { BookingList } from '../BookingList/BookingList';
import { useSelector } from 'react-redux';
import { Box, Button, Divider, Fade, Stack, Typography } from '@mui/material';
import { Link, useLocation } from "react-router-dom";

export function BookingData() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const consult = params.get('consult') ?? false;
  const orderList = useSelector((store) => store.cartSlice.selectedStaff);

  return (
      <Fade in style={{ transitionDuration: '1s' }}>
        {orderList.length ? (
            <Stack gap={'1rem'} maxWidth={'40rem'}>
              <Typography
                  fontWeight={'bold'}
                  variant={'h3'}
                  color={'primary'}
                  gutterBottom
                  sx={{
                    fontVariationSettings: `'wdth' 100, 'wght' 900`,
                    textAlign: 'center',
                  }}
              >
                Congratulations!
              </Typography>
              <Box>
                <Typography variant={'h5'} sx={{
                  fontVariationSettings: `'wdth' 95, 'wght' 700`,
                }}>
                  Your remote {orderList.length > 1 ? 'team' : 'employee'} almost ready.
                </Typography>
                <Typography variant={'body1'}>
                  Please check everything again, fill out the form and we will arrange a meeting with the selected
                  candidates
                </Typography>
              </Box>

              <Typography color={'primary'} padding={'0'} sx={{
                fontVariationSettings: `'wdth' 95, 'wght' 700`,
              }}>
                {`${orderList.length} employee${orderList.length > 1 ? 's' : ''} now added for interview:`}
              </Typography>
              <Box>
                <BookingList orderList={orderList}/>
              </Box>
            </Stack>
        ) : (
            <Box sx={{
              alignSelf: 'center',
              textAlign: 'center'
            }}
            >
              <Typography
                  variant='h5'
              >
                {consult ? `Didn't find what you were looking for?` : `Build Your Ultimate Remote Team`}
              </Typography>
              <Box>
                {consult
                    ? 'Schedule meet up and we will try to find something special for you.'
                    : <Box paddingTop={'2dvh'} display={'flex'} flexDirection={'column'} gap={'1.5dvh'}>
                      <Typography
                          variant={'body1'}
                          fontWeight={'bold'}
                          textAlign={'center'}
                          color={'primary'}
                          gutterBottom
                          sx={{
                            textWrap: 'balance',
                          }}
                      >
                        {`You have not added any interview staff at this time. It's very easy to do:`}
                      </Typography>
                      <Box
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'flex-start'}
                          padding={'1rem'}
                          gap={'1.5dvh'}
                          border={'1px solid #ccc'}
                          borderRadius={'1rem'}
                      >
                        <Typography variant={'body1'} sx={{ textAlign: 'start', textWrap: 'wrap' }}>
                          {`1. Go to the `}
                          <Link href={'/'} to={'/'} style={{ fontWeight: 'bold', textDecoration: 'none' }}>our
                            catalogue</Link>
                          {` page and select the employee you want to interview.`}
                        </Typography>
                        <Typography variant={'body1'} sx={{ textAlign: 'start', textWrap: 'wrap' }}>
                          {`2. Click on the "Add to interview" button in any CV page.`}
                        </Typography>
                        <Typography variant={'body1'} sx={{ textAlign: 'start', textWrap: 'wrap' }}>
                          {`3. Click on the "Book a call" button, fill out the form and just send it!`}
                        </Typography>
                      </Box>
                      <Button component={Link} to={'/'} size={'large'} variant='contained' sx={{
                        marginTop: '3dvh',
                        alignSelf: 'center',
                        borderRadius: '.5rem',
                      }}>
                        explore employee catalogue
                      </Button>
                      <Divider sx={{ padding: '2dvh', fontWeight: 'bold' }}>OR</Divider>
                      <Typography
                          variant={'body1'}
                          fontWeight={'bold'}
                          textAlign={'center'}
                      >
                        {`Just send us a message and we will help you find the right employee for your purposes. 😉`}
                      </Typography>
                    </Box>
                }
              </Box>
            </Box>
        )}
      </Fade>
  );
}
