import { Box, FormLabel, Slider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { storeIDs } from "../../../../lib/mapData";

function valueLabelFormat(value) {
  const possibleValues = ['40h+', '80h+', '160h+'];
  return possibleValues[value];
}

function valueSliceFormat(value) {
  const possibleValues = ['any', 'part', 'full'];
  return possibleValues[value];
}

const marks = [
  {
    value: 0,
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
];

export default function RateSlider({ id }) {
  const dispatch = useDispatch();
  const { maxRate, isFiltered } = useSelector(state => state.filterSlice);

  const [value, setValue] = useState(0);
  const initialRef = useRef(null);

  useEffect(() => {
    if (initialRef.current) {
      const action = storeIDs.find((a) => a.id === id).filterAction;
      const formattedValue = valueSliceFormat(value);
      dispatch(action(formattedValue));
    } else {
      initialRef.current = true;
    }
  }, [dispatch, id, value]);

  const handleChange = (_, newValue) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (!isFiltered) {
      setValue(0);
    }
  }, [isFiltered]);

  return (
      <>
        <FormLabel>Availability per month: {valueLabelFormat(value)}</FormLabel>
        <Box sx={{ padding: '0 .8rem' }}>
          <Slider
              value={value}
              min={0}
              step={1}
              max={maxRate}
              onChange={handleChange}
              marks={marks}
              valueLabelDisplay='auto'
              valueLabelFormat={(v) => valueLabelFormat(v)}

          />
        </Box>
      </>
  );
}
