import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grow,
  Popper,
  Stack, styled,
  Typography, useTheme,
  Zoom,
} from "@mui/material";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import SpaLink from "../SpaLink/SpaLink";
import { EmpSkills } from "./components/EmpSkills";
import { useSelector } from "react-redux";

const AnimatedCircle = styled(Box)(({ bg, opacity }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  background: bg,
  opacity: opacity,
  backgroundPosition: 'center',
  animation: 'none',
}));

function EmployeeCard({ person: emp, small }) {
  const { style, cardsConfig } = useSelector(state => state.settingsSlice.settings)
  const theme = useTheme();

  const [pricing, setPricing] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const parsePricing = useCallback((data) => {
    const pricingArray = [];
    if (data.full_price) {
      pricingArray.push({
        name: "full-time",
        symbol: data.full_symbol ? data.full_symbol : "",
        price: Math.round(data.full_price * cardsConfig.priceCoeff),
        hours: 160,
      });
    }
    if (data.part_price) {
      pricingArray.push({
        name: "part-time",
        symbol: data.full_symbol ? data.full_symbol : "",
        price: Math.round(data.part_price * cardsConfig.priceCoeff),
        hours: 80,
      });
    }
    if (data.min_price) {
      pricingArray.push({
        name: "minimal",
        symbol: data.full_symbol ? data.full_symbol : "",
        price: Math.round(data.min_price * cardsConfig.priceCoeff),
        hours: 40,
      });
    }
    return pricingArray;
  }, [cardsConfig.priceCoeff]);

  useEffect(() => {
    setPricing(() => parsePricing(emp));
  }, [emp, parsePricing]);

  const popoverContent = useMemo(() => {
    return (
        <Stack flexDirection={'column'}>
          {pricing.length > 0 ? (
              pricing.map((p, i, {length: len}) => {
                const rate = Number(emp.sum_rate_rh);
                const isUnavailablePrice = emp.sum_rate_rh ? rate < 1 && i === 0 && len >= 3 : false;


                return (
                    <Typography
                        key={p.name}
                        variant={"caption"}
                        color={isUnavailablePrice ? '#ccc' : style.main.primaryColor}
                        sx={{
                          textDecoration: isUnavailablePrice ? 'line-through' : 'none',
                          fontWeight: 'bold',
                        }}
                    >
                      {p.name} : {p.symbol}{p.price}
                    </Typography>
                );
              })
          ) : (
              <div>
                <p>Sorry, there is not pricing for an employee</p>
              </div>
          )}
        </Stack>
    );
  }, [emp.sum_rate_rh, pricing, style.main.primaryColor]);

  return small ? (
      <Zoom in={true} timeout={300} unmountOnExit>
        <Card
            component={"article"}
            variant="outlined"
            sx={{
              minWidth: '20rem',
              width: '20rem',
              maxWidth: '30%',
              flexGrow: '1',
              lineHeight: '1'
            }}
        >
          <CardContent
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "stretch",
                gap: "1rem",
                justifyContent: "flex-start",
              }}
          >
            <Stack
                component={"section"}
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
            >
              <Box
                  component={"picture"}
                  sx={{
                    alignItems: "center",
                    aspectRatio: 1,
                    background: `${style.card.avatarBackground}` || 'linear-gradient(90deg,#0f8,#61efff)',
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    overflow: "hidden",
                    userSelect: "none",
                    width: "5rem",
                    transition: "all .2s ease-in-out",
                  }}
              >
                <CardMedia
                    component="img"
                    image={emp.image_url}
                    alt={`${emp.short_name} - ${emp.position_name}`}
                    sx={{
                      userSelect: "none",
                      filter: "drop-shadow(.8rem .8rem .5rem rgba(0,0,0,.2))",
                      transition: "all .2s ease-in-out",
                      "&:hover": {
                        scale: "1.1",
                      },
                    }}
                />
              </Box>
              <Chip
                  icon={<FingerprintIcon color={"primary"}/>}
                  label={emp.employee_id}
                  variant={"outlined"}
                  sx={{
                    position: "absolute",
                    bottom: "-.5rem",
                    width: '100px',
                    backgroundColor: "rgba(255,255,255,.98)",
                    fontVariationSettings: `'wdth' 100, 'wght' 700`,
                  }}
                  size={"small"}
              />
            </Stack>
            <Stack
                component={"section"}
                flexGrow={1}
                sx={{
                  justifyContent: "center",
                  gap: ".5rem",
                }}
            >
              <Typography
                  variant={"h5"}
                  align={"center"}
                  sx={{
                    fontSize: '1rem',
                    fontVariationSettings: `'wdth' 95,'wght' 700`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
              >
                {emp.short_name}
                <Chip
                    label={`${emp.full_symbol}
				                                    ${Number(emp.sum_rate_rh) === 1
                        ? Math.round(emp.full_price * cardsConfig.priceCoeff)
                        : Math.round(emp.part_price * cardsConfig.priceCoeff)}`}
                    size={"small"}
                    sx={{
                      padding: ".5rem",
                      backgroundColor: `#ffffff`,
                      fontVariationSettings: `'wdth' 100, 'wght' 700`,
                      fontSize: "1rem",
                      color: `${
                          Number(emp.sum_rate_rh) >= 1 || emp.sum_rate_rh === null
                              ? theme.palette.primary.main
                              : theme.palette.secondary.main
                      }`,
                      "& :first-letter": {
                        fontSize: ".75em",
                        verticalAlign: "top",
                      },
                      "& :last-child": {
                        overflow: "visible",
                      },
                      userSelect: "none",
                    }}
                ></Chip>
              </Typography>
              <Typography variant={"caption"} lineHeight={1}>
                {emp.position_name}
              </Typography>
            </Stack>
          </CardContent>
          <CardActions
              sx={{
                width: "100%",
                padding: 0,
                borderTop: `solid 1px #f1f1f1`,
              }}
          >
            <SpaLink
                to={`/${emp.slug}`}
                sx={{
                  width: "100%",
                }}
            >
              <Button
                  size="medium"
                  fullWidth
                  sx={{
                    borderRadius: "0",
                  }}
              >
                Learn More
              </Button>
            </SpaLink>
          </CardActions>
        </Card>
      </Zoom>
  ) : (
      <Zoom in={true} timeout={300} unmountOnExit>
        <Card
            component={"article"}
            sx={{
              width: "20rem",
              aspectRatio: "0.66",
              display: "flex",
              flexFlow: "column nowrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
        >
          <Box
              component={"header"}
              sx={{
                width: "100%",
                minHeight: "3rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: ".5rem",
              }}
          >
            <Chip
                aria-owns={!!anchorEl ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                label={`
						${emp.full_symbol}
						${Number(emp.sum_rate_rh) === 1
                    ? Math.round(emp.full_price * cardsConfig.priceCoeff)
                    : Math.round(emp.part_price * cardsConfig.priceCoeff)}`}
                size={"small"}
                sx={{
                  padding: ".5rem",
                  backgroundColor: `#ffffff`,
                  fontVariationSettings: `'wdth' 100, 'wght' 700`,
                  fontSize: "1rem",
                  color: `${
                      Number(emp.sum_rate_rh) >= 1 || emp.sum_rate_rh === null
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main
                  }`,
                  "& :first-letter": {
                    fontSize: ".75em",
                    verticalAlign: "top",
                  },
                  "& :last-child": {
                    overflow: "visible",
                  },
                  userSelect: "none",
                  cursor: "pointer",
                }}
                onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
            ></Chip>
            <Popper
                id='mouse-over-popover'
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                placement='bottom-start'
                transformorigin={{ vertical: 'top', horizontal: 'left' }}
                transition
                sx={{
                  pointerEvents: 'none',
                }}
            >
              {({ TransitionProps }) => (
                  <Grow {...TransitionProps}>
                    <Box
                        sx={{
                          padding: '.8rem 1rem',
                          backgroundColor: 'rgba(255, 255, 255, .6)',
                          borderRadius: '1rem',
                          backdropFilter: 'blur(1rem) saturate(180%)',
                          boxShadow: '0 .05rem .15rem rgba(0,0,0,.2)',
                        }}
                    >
                      {popoverContent}
                    </Box>
                  </Grow>
              )}
            </Popper>
            <Chip
                variant="outlined"
                size={"small"}
                label={`${
                    Number(emp.sum_rate_rh) >= 1 || emp.sum_rate_rh === null
                        ? "Full"
                        : "Part"
                } time charged`}
                sx={{
                  padding: ".5rem",
                  borderColor: `${
                      Number(emp.sum_rate_rh) >= 1 || emp.sum_rate_rh === null
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main
                  }`,
                  color: `${
                      Number(emp.sum_rate_rh) >= 1 || emp.sum_rate_rh === null
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main
                  }`,
                  fontVariationSettings: `'wdth' 90, 'wght' 500`,
                }}
            />
          </Box>

          <CardActionArea
              sx={{
                borderRadius: "0",
                "& .MuiCardActionArea-focusHighlight": {
                  backgroundColor: theme.palette.primary.main,
                },
                "& .MuiTouchRipple-root": {
                  opacity: 0.1,
                },
                '&:hover .animated-circle:nth-of-type(2)': {
                  // Target the animated circles on hover
                  animation: 'pulse 1s ease-in-out',
                },
                '&:hover .animated-circle:nth-of-type(3)': {
                  // Target the animated circles on hover
                  animation: 'pulse2 1s ease-in-out',
                },
              }}
          >
            <SpaLink to={`/${emp.slug}`} sx={{
              borderRadius: "0"
            }}>
              <CardContent
                  sx={{
                    width: "100%",
                    padding: "0 0 .5rem 0",
                    borderRadius: "0"
                  }}
              >
                <Stack
                    component={"section"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexFlow: "column nowrap",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      padding: ".5rem 0 1rem 0",
                    }}
                >
                  {/*<Box*/}
                  {/*    component={"picture"}*/}
                  {/*    sx={{*/}
                  {/*      width: "12rem",*/}
                  {/*      aspectRatio: 1,*/}
                  {/*      display: "flex",*/}
                  {/*      borderRadius: "50%",*/}
                  {/*      overflow: "hidden",*/}
                  {/*      justifyContent: "center",*/}
                  {/*      alignItems: "center",*/}
                  {/*      // background: `${style.card.avatarBackground}` || "linear-gradient(90deg,#0f8,#61efff)",*/}
                  {/*      background: 'radial-gradient(#FFCA28 50%, #00B0FF 50%,  #18FFFF 75%, #ffffff 85%)',*/}
                  {/*      transition: 'all .2s ease-in-out',*/}
                  {/*      backgroundPosition: 'top center',*/}
                  {/*      backgroundSize: '1100% 1100%',*/}
                  {/*      '&:hover': {*/}
                  {/*        background: 'radial-gradient(#FFCA28 50%, #00B0FF 50%,  #18FFFF 75%, #ffffff 85%)',*/}
                  {/*        backgroundSize: '300% 300%',*/}
                  {/*        backgroundPosition: 'top center',*/}
                  {/*      },*/}
                  {/*      userSelect: "none",*/}
                  {/*    }}*/}
                  {/*>*/}
                  {/*  <CardMedia*/}
                  {/*      component="img"*/}
                  {/*      image={emp.image_url}*/}
                  {/*      alt={`${emp.short_name} - ${emp.position_name}`}*/}
                  {/*      sx={{*/}
                  {/*        userSelect: "none",*/}
                  {/*        filter: "drop-shadow(.8rem .8rem .5rem rgba(0,0,0,.2))",*/}
                  {/*        transition: "all .2s ease-in-out",*/}
                  {/*        "&:hover": {*/}
                  {/*          scale: "1.1",*/}
                  {/*        },*/}
                  {/*      }}*/}
                  {/*  />*/}
                  {/*</Box>*/}
                  <Box
                      component={"picture"}
                      sx={{
                        position: "relative",
                        width: "12rem",
                        aspectRatio: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        overflow: "hidden",
                        '@keyframes pulse': {
                          '0%': {
                            scale: '1',
                          },
                          '50%': {
                            scale: '1.13',
                          },
                          '100%': {
                            scale: '1',
                          },
                        },
                        '@keyframes pulse2': {
                          '0%': {
                            scale: '1',
                          },
                          '50%': {
                            scale: '1.30',
                          },
                          '100%': {
                            scale: '1',
                          },
                        },
                        userSelect: "none",
                      }}
                  >
                    {/*<div className={s.circleContainer}>*/}
                    {/*  <div className={s.circle} style={{ backgroundColor: style.card.avatarBackground, opacity: 0.2 }}></div>*/}
                    {/*  <div className={s.circle} style={{*/}
                    {/*    backgroundColor: style.card.avatarBackground,*/}
                    {/*    width: "84%",*/}
                    {/*    height: "84%",*/}
                    {/*    opacity: 0.5,*/}
                    {/*    top: "8%",*/}
                    {/*    left: "8%"*/}
                    {/*  }}></div>*/}
                    {/*  <div className={s.circle} style={{*/}
                    {/*    backgroundColor: style.card.avatarBackground,*/}
                    {/*    width: "68%",*/}
                    {/*    height: "68%",*/}
                    {/*    opacity: 1,*/}
                    {/*    top: "16%",*/}
                    {/*    left: "16%"*/}
                    {/*  }}></div>*/}
                    {/*</div>*/}
                    <AnimatedCircle
                        className='animated-circle'
                        bg={style.card.avatarBackground}
                        opacity={'.2'}
                    />
                    <AnimatedCircle
                        className='animated-circle'
                        bg={style.card.avatarBackground}
                        opacity={'.5'}
                        sx={{ width: '84%', height: '84%' }}
                    />
                    <AnimatedCircle
                        className='animated-circle'
                        bg={style.card.avatarBackground}
                        opacity={'1'}
                        sx={{ width: '68%', height: '68%' }}
                    />
                    <CardMedia
                        component="img"
                        image={emp.image_url}
                        alt={`${emp.short_name} - ${emp.position_name}`}
                        sx={{
                          filter: "drop-shadow(.8rem .8rem .5rem rgba(0,0,0,.2))",
                          transition: "all .2s ease-in-out",
                          // "&:hover": {
                          //   scale: "1.1",
                          // },
                        }}
                    />
                  </Box>

                  <Chip
                      icon={<FingerprintIcon color={"primary"}/>}
                      label={emp.employee_id}
                      sx={{
                        padding: ".5rem",
                        position: "absolute",
                        bottom: ".5rem",
                        backgroundColor: "rgba(255,255,255,.98)",
                        boxShadow: "0 .1rem .3rem rgba(0,0,0,.1)",
                        // fontFamily: "unset",
                        // fontWeight: "700",
                        fontVariationSettings: `'wdth' 100, 'wght' 700`,
                      }}
                  />
                </Stack>
                <Stack component={"section"} width={"100%"} flexGrow={1}>
                  <Typography
                      variant={"h5"}
                      align={"center"}
                      // fontFamily={"unset"}
                      sx={{
                        // fontWeight: "700",
                        fontVariationSettings: `'wdth' 95, 'wght' 700`,
                      }}
                  >
                    {emp.short_name}
                  </Typography>
                  <Typography
                      variant={"subtitle1"}
                      align={"center"}
                      lineHeight={1}
                  >
                    {emp.position_name}
                  </Typography>
                </Stack>
              </CardContent>
            </SpaLink>
          </CardActionArea>

          <Box
              component={"section"}
              sx={{
                width: "100%",
                flexGrow: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexFlow: "row wrap",
                alignContent: "center",
                padding: "0 1rem",
                gap: ".5rem .3rem",
                textWrap: "balance",
              }}
          >
            <EmpSkills
                empSkills={emp.skills}
            />
          </Box>
          <CardActions
              sx={{
                width: "100%",
                padding: 0,
              }}
          >
            <SpaLink
                to={`/${emp.slug}`}
                sx={{
                  width: "100%",
                }}
            >
              <Button
                  size="large"
                  fullWidth
                  sx={{
                    borderRadius: "0",
                  }}>
                Learn More
              </Button>
            </SpaLink>
          </CardActions>
        </Card>
      </Zoom>
  );
}

export { EmployeeCard };
