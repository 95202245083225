import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import { LoadingButton } from '@mui/lab';
import { blue, red } from '@mui/material/colors';
import {
  Box,
  Button,
  ButtonGroup,
  FormGroup,
  Link as MUILink,
  Paper,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from '@mui/icons-material/Done';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import { MuiTelInput } from 'mui-tel-input';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from "react-router-dom";
import { dateTimeGlobals } from "../../lib/globals";
import { getLang, getTimeDifference, setDefaultDate } from "../../lib/order-helpers";
import { isFormValid, validateOrderData } from "../../lib/validateData";
import { removeAllEmployees, removeUnavailableEmployees } from "../../redux/slices/cartSlice";
import { saveState } from "../../redux/localStorage";

export const getCountryByIP = async (setUserLocationData) => {
  try {
    const ipify = await fetch('https://api.ipify.org?format=json');
    const { ip } = await ipify.json();
    const ipapi = await fetch(`https://ipapi.co/${ip}/json/`);
    const dataByIP = await ipapi.json();
    setUserLocationData({
      country_code: dataByIP.country_code,
      timezone: dataByIP.timezone,
    });
  } catch (error) {
    console.error('error', error);
  }
};

export const BookingForm = () => {
  const orderList = useSelector((store) => store.cartSlice.selectedStaff);
  const { affiliateCode } = useSelector((store) => store.settingsSlice);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const consult = params.get('consult') ?? false;
  const { addedTime, start, end, weekends, activeDays } = dateTimeGlobals;
  const [dateValue, setDateValue] = useState(setDefaultDate(addedTime));
  const [customersName, setCustomersName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [userLocationData, setUserLocationData] = useState({ country_code: 'DE', timezone: 'Europe/Berlin' });
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState(null);
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: '',
    dateTime: '',
  });
  const locale = getLang();
  const [phone, setPhone] = useState('');

  const handleChangePhone = (newPhone) => {
    const validationResult = validateOrderData('phone', newPhone);
    setErrors((prev) => ({ ...prev, phone: validationResult ?? '' }));
    setPhone(newPhone);
  };

  const handleChange = ({ target }) => {
    const validationResult = validateOrderData(target.name, target.value);
    setErrors((prev) => ({ ...prev, [target.name]: validationResult ?? null }));
    switch (target.name) {
      case 'name':
        setCustomersName(target.value);
        break;
      case 'email':
        setEmail(target.value);
        break;
      case 'comment':
        setComment(target.value);
        break;
      default:
        break;
    }
  };

  const disableTime = (value, view) => {
    const timeDifference = getTimeDifference(userLocationData.timezone);
    const startTime = start + timeDifference;
    const endTime = end + timeDifference;
    return view === 'hours' && (value.hour() < startTime || value.hour() > endTime);
  };

  async function post_google_calendar(data) {
    try {
      const response = await fetch('https://sender.rh-s.com/api/calendar', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_RHS_GOOGLE_SERVICES_TOKEN}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async () => {
    const formData = {
      customersName,
      phone,
      email,
      dateValue,
      comment,
    };
    const validation = isFormValid({ name: customersName, phone, email }, errors);
    if (validation.result) {
      setIsLoading(true);
      await dispatch(removeUnavailableEmployees());
      const dataToSend = {
        ...formData,
        employeesList: orderList,
        customersName: `Code: ${affiliateCode} - ${customersName} `,
        consult,
        appLink: window.location.origin
      };
      await post_google_calendar(dataToSend)
      setCustomersName('')
      setEmail('')
      setComment('')
      setPhone('')
      setIsLoading(false);
      saveState([])
      dispatch(removeAllEmployees())
    } else {
      setErrors(() => ({ ...validation.errors }));
    }
  };

  useEffect(() => {
    getCountryByIP(setUserLocationData);
  }, []);

  return (
      <Slide in direction={'left'} style={{ transitionDuration: '.6s' }}>
        <Paper sx={{ position: 'relative', padding: '3dvh 1.5rem', maxWidth: '31rem', width: '100%' }}>
          <FormGroup>
            <Stack sx={{ flexFlow: 'column nowrap', gap: '2.2dvh' }}>
              <TextField
                  id='name-input'
                  label='Name'
                  variant='outlined'
                  onChange={handleChange}
                  name='name'
                  value={customersName}
                  error={!!errors.name}
                  helperText={errors.name ?? ''}
              />
              <MuiTelInput
                  label={'Phone'}
                  name='phone'
                  placeholder=' '
                  value={phone}
                  onChange={handleChangePhone}
                  defaultCountry={userLocationData.country_code}
                  forceCallingCode
                  focusOnSelectCountry
                  excludedCountries={['RU']}
                  preferredCountries={[
                    'CH',
                    'DE',
                    'US',
                    'SE',
                    'GB',
                    'NL',
                    'FR',
                    'FI',
                    'PT',
                    'CZ',
                    'CA',
                    'IE',
                    'IL',
                    'IT',
                    'DK',
                    'IS',
                    'LV',
                    'MT',
                    'NO',
                    'ES',
                    'SK',
                    'BE',
                    'LU',
                    'AT',
                    'NZ',
                    'PL',
                    'SG',
                  ]}
                  continents={['EU', 'OC', 'NA', 'SA']}
                  error={!!errors.phone}
                  helperText={errors.phone ?? ''}
              />

              <TextField
                  id='email-input'
                  label='Email'
                  variant='outlined'
                  onChange={handleChange}
                  name='email'
                  type='email'
                  value={email}
                  error={!!errors.email}
                  helperText={errors.email ?? 'DE'}
              />

              <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs} actionBar={false}>
                <DemoContainer components={['MobileDateTimePicker']} sx={{ marginTop: '-.5rem', height: '6rem' }}>
                  <MobileDateTimePicker
                      label='Choose date and time'
                      onError={(newError) =>
                          setErrors((prev) => ({
                            ...prev,
                            dateTime: newError ? 'You can choose only working days and hours' : '',
                          }))
                      }
                      slotProps={{
                        textField: {
                          position: 'absolute',
                          helperText: errors.dateTime,
                        },
                      }}
                      minutesStep={5}
                      value={dateValue}
                      onChange={(newValue) => setDateValue(newValue)}
                      disablePast
                      shouldDisableDate={(date) => date.day() === weekends[0] || date.day() === weekends[1]}
                      shouldDisableTime={disableTime}
                      maxDate={dayjs(Date.now() + activeDays)}
                      ampm={false}
                      views={['month', 'day', 'hours', 'minutes']}
                      sx={{ backgroundColor: 'transparent' }}
                  />
                </DemoContainer>
              </LocalizationProvider>

              <TextField
                  id='comment-input'
                  label='Leave your comment'
                  variant='outlined'
                  onChange={handleChange}
                  name='comment'
                  value={comment}
                  multiline
                  sx={{ marginTop: '-2rem' }}
              />
              <LoadingButton
                  size={'large'}
                  loading={isLoading}
                  loadingPosition='end'
                  endIcon={<SendIcon/>}
                  variant='contained'
                  onClick={handleSubmit}
                  sx={{
                    marginTop: '1dvh',
                    borderRadius: '.5rem',
                  }}
              >
                Send
              </LoadingButton>
            </Stack>
          </FormGroup>
          <AnimatePresence>
            {event && (
                <Stack
                    component={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    sx={{
                      position: 'absolute',
                      top: '-5px',
                      left: '0',
                      bgolor: '#fff',
                      width: '100%',
                      height: 'calc(100% + 5px)',
                      zIndex: '100',
                      boxShadow: '0px 0px 3px rgba(0, 0, 0, .8)',
                      borderRadius: '4px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                >
                  <Stack
                      sx={{
                        alignItems: 'center',
                        gap: '1.5rem',
                      }}
                  >
                    <Box
                        sx={{
                          p: '.5rem',
                          border: `5px solid ${event.success ? blue[500] : 'transparent'}`,
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                    >
                      {event.success ? (
                          <DoneIcon sx={{ fontSize: '5rem', color: blue[500] }}/>
                      ) : (
                          <SmsFailedIcon sx={{ fontSize: '5rem', color: red[500] }}/>
                      )}
                    </Box>
                    <Typography sx={{ fontSize: 'clamp(1rem, 2vw, 1.3rem)' }}>
                      {event.success
                          ? 'You are scheduled with RemoteHelpers'
                          : 'Sorry, something went wrong. Please, contact us.'}
                    </Typography>
                    {event.success ? (
                        <ButtonGroup
                            variant='contained'
                            aria-label='outlined primary button group'
                            sx={{ gap: '1rem', boxShadow: 'none' }}
                        >
                          <Button>Catalog</Button>
                          <Button>Terms&Conditions</Button>
                          <Button>Other</Button>
                        </ButtonGroup>
                    ) : (
                        <MUILink variant='button' underline='none' href='mailto:sales@rh-s.com'>
                          Contact us
                        </MUILink>
                    )}
                  </Stack>
                  <Button sx={{ position: 'absolute', top: '0', right: '0' }} onClick={() => setEvent(null)}>
                    <CloseIcon sx={{ fontSize: '2.5rem' }}/>
                  </Button>
                </Stack>
            )}
          </AnimatePresence>
        </Paper>
      </Slide>
  );
};
