import { createSlice } from "@reduxjs/toolkit";
import { loadState, saveState } from "../localStorage";

const initialState = {
  selectedStaff: loadState() ? loadState() : [],
};

function updateStorage(state) {
  saveState(state.selectedStaff);
  console.log("store updated");
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    createStaffItem: (state, action) => {
      const {
        department,
        employee_id,
        slug,
        position_name,
        short_name,
        image_url,
        full_price,
        part_price,
        sum_rate_rh,
        full_symbol,
      } = action.payload;

      state.selectedStaff.push({
        department,
        employee_id,
        slug,
        position_name,
        short_name,
        image_url,
        full_price,
        part_price,
        sum_rate_rh,
        full_symbol,
      });
      updateStorage(state);
    },
    removeStaffItem: (state, action) => {
      state.selectedStaff = state.selectedStaff.filter(
          (obj) => obj.employee_id !== action.payload
      );
      updateStorage(state);
    },
    removeUnavailableEmployees: (state) => {
      state.selectedStaff = state.selectedStaff.filter(employee => Number(employee.sum_rate_rh) > 0)
    },
    removeAllEmployees: (state) => {
      state.selectedStaff = []
    },
  },
});
export const { createStaffItem,
  removeStaffItem,
  removeUnavailableEmployees, removeAllEmployees
} = cartSlice.actions;

export default cartSlice.reducer;
