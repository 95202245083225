export const gFetch = {
  crm: {
    url: 'https://crm-s.com/api/v1',
    globalCompany: 'Remote Helpers',
    path: function (url, params) {
      return `${this.url}/${url}${params ? `?${new URLSearchParams(params)}` : ''}`;
    },
  },
  newStrapi: {
    url: 'https://cnt.rhelpers.com',
    photoUrl: 'https://cnt.rhelpers.com',
    token:
        'Bearer 5c5a0b31adbada4b61179ec1e288ec503339250be498a2ddf42692a9e8bd4cd45fe40682334a2d2032e9d466159d1c762434259f8685745f1354c0f9a213bdc8360a250dd8d413798a6b60e87564667d4750752897fde149634f7a9745683ec9371bfef21d13af2d94282eeea1fde21ca10f0461b628eb507219daa05073b147',
    populate: 'deep',
    path: function (url, params) {
      return `${this.url}${url}${params ? `?${new URLSearchParams(params)}` : ''}`;
    },
  },
};

export async function get_strapi(url, options) {
  const updatedOptions = options?.next || options?.cache ? options : { next: { revalidate: 10 }, ...options };
  try {
    const response = await fetch(url, updatedOptions);
    if (response.ok) {
      let json = await response.json();
      return json.data;
    } else {
      console.log('▓▓▓▓▓ Strapi HTTP ERROR => ', response);
    }
  } catch (error) {
    console.error(error);
  }
}

export const qlQueries = {

  getPositionVideo(position) {
    return {
      query: `query {
        positions(filters:  { slug: { eq: "${position}" } }) {
          data {
            attributes {
              video
            }
          }
        }
      }`,
    };
  },
  getDepartmentVideo(department) {
    return {
      query: `query {
        departments(filters:  { slug: { eq: "${department}" } }) {
          data {
            attributes {
              video
            }
          }
        }
      }`,
    };
  },
};
