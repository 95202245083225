import { SwipeableDrawer, Typography } from '@mui/material';
import React from 'react';
import { Filters } from "../Filters";

export default function FiltersDrawer({ filterOpen, setFilterOpen, current, total }) {
  return (
      <SwipeableDrawer
          disableScrollLock={true}
          anchor="right"
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onOpen={() => setFilterOpen(true)}
          disableDiscovery
          disableBackdropTransition
          swipeAreaWidth={0}
          PaperProps={{
            sx: {
              padding: '3rem 1.5rem',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              flexFlow: 'column nowrap',
              minWidth: '16rem',
              borderRadius: '0'
            },
          }}
      >
        <Typography
            color={"primary"}
            variant={"h5"}
            width={'100%'}
            sx={{
              fontVariationSettings: `'wdth' 95, 'wght' 800`,
            }}>
          Employee filter
        </Typography>
        <Filters total={total} current={current} setFilterOpen={setFilterOpen}/>
      </SwipeableDrawer>
  );
}
