import { Routes, Route } from "react-router-dom";
import { Layout } from "./Layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFilterData, getCards } from "../redux/slices/filterSlice";
import { CartPage } from "../pages/Cart";
import { HomePage } from "../pages/Home";
import { CV } from "./CV";
import { NothingFoundPage } from "../pages/404";
import { getSettings } from "../redux/slices/settingsSlice";

function App() {
  const dispatch = useDispatch()
  const { filter } = useSelector(state => state.settingsSlice.settings)
  const [filterLoaded, setFilterLoaded] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);

  const customersAffiliateCode = searchParams.get('affiliateCode')

  useEffect(() => {
    const fetchData = async () => {
      customersAffiliateCode && await dispatch(getSettings(customersAffiliateCode));
      await dispatch(fetchFilterData());
      setFilterLoaded(true);
    };
    fetchData();
  }, [dispatch, customersAffiliateCode]);

  useEffect(() => {
    if (filterLoaded) {
      dispatch(getCards(filter));
    }
  }, [dispatch, filter, filterLoaded]);

  return (
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route path=':slug' element={<CV/>}/>
          <Route index element={<HomePage/>}/>
          <Route path='cart' element={<CartPage/>}/>
          <Route path='404' element={<NothingFoundPage/>}/>
        </Route>
      </Routes>
  );
}

export default App;
